// Fixme
const config = {
  production: false,
  production_backend: "https://hermiqs.falk.crs-medical.com/",
  test_backend: "https://hermiqs.falk.crs-medical.com/",
};

let backend_uri;

if (config.production) {
  backend_uri = config.production_backend;
} else {
  backend_uri = config.test_backend;
}

module.exports = { backend_uri };
